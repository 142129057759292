import * as Sentry from '@sentry/browser';
import './currencyExchange';

Sentry.init({
    dsn: window.pet_config.sentry_dsn_frontend,
    release: window.pet_config.app_release,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.breadcrumbsIntegration({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true,
        })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    // allowUrls: [
    //     /https?:\/\/(?:www\.)?paw-champ\.com/,
    // ],
    ignoreErrors: [
        "Password check response has error",
        "Password check response incorrect",
    ],
});

function formatMoney(money) {
    return Math.floor(money * 100) / 100;
}

const fetchRetry = (url, options = {}, retries = 5, interval = 1000) => fetch(url, options)
    .then(async (res) => {
        if (res.ok) {
            return res.json();
        }
        if (retries > 0) {
            await new Promise((r) => setTimeout(r, interval));

            return fetchRetry(url, options, retries - 1, interval);
        }
        throw new Error(res.status);
    })
    .catch((error) => console.error(error.message));

function replaceTextForMixedBreed(breed) {
    if (breed === null) {
        return;
    }

    const dynamicTextSelectors = document.querySelectorAll('.dynamic-text');

    if (breed === 'Mixed breed' || breed === 'Mixed') {
        dynamicTextSelectors.forEach((el) => {
            if (el.classList.contains('default')) {
                el.style.setProperty('display', 'none');
            } else if (el.classList.contains('mixed-breed')) {
                el.style.setProperty('display', 'block');
            }
        });
    }
}

export {
    formatMoney, fetchRetry, replaceTextForMixedBreed,
};
